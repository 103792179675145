// Content component for rendering content blocks
// Props:
//  - title: string
//  - content: string
//  - multipleColumns: boolean
//  - children: any

export default function Content(props) {
  let columnCount;
  let columnClass = ["paragraph flow--16"];

  if (props.multipleColumns) {
    const pTagCount = props.content.match(/<p>/g);
    columnCount = pTagCount ? pTagCount.length : 0;
  }

  if (columnCount === 2) {
    columnClass.push("paragraph--col-2");
  }
  if (columnCount === 3) {
    columnClass.push("paragraph--col-3");
  }

  return (
    <>
      <div className={props.showContainer ? "flow--12 checkout--container" : "flow--12"}>
        {props.title && <h3 className='h4 semi'>{props.title}</h3>}
        {props.content ? <div className={columnClass.join(" ")} dangerouslySetInnerHTML={{ __html: props.content }}></div> : props.children}
      </div>
    </>
  );
}
