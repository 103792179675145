import React from 'react';
import ReactDOM from 'react-dom/client';
import LicenseCheck from './licenseCheck';
import { Provider } from 'react-redux';
import store from './redux/store';

let retryLimit = 5;
let root;
const entryPointNode = document.getElementById('acl');

function RenderApp() {
  if (entryPointNode) {
    if (!root || !entryPointNode.hasChildNodes()) {
      if (root) {
        // Re-create the root every time RenderApp is called.
        // Otherwise if the DOM changes, React may complain that we didn't unmount properly
        root = root.unmount();
      }

      root = ReactDOM.createRoot(entryPointNode);
      console.log("Loading VAP Boost");

      root.render(
        <React.StrictMode>
          <Provider store={store}>
            <LicenseCheck license={entryPointNode.getAttribute('data-license')} />
          </Provider>
        </React.StrictMode>
      );
    }
  }
}

const initialLoadTimer = setTimeout(RenderApp, 2000);
const retryLoadTimer = setInterval(() => {
  if (!entryPointNode.hasChildNodes()) {
    console.log("Retrying loading VAP Boost")
    if (retryLimit-- > 0) {
      RenderApp();
    } else {
      clearInterval(retryLoadTimer);
      clearTimeout(initialLoadTimer);
    }
  }
}, 2500);
